import axios from "axios";

const http = axios.create({
  // baseURL: "http://backend.zione.tech:8080/01-zamjyAPI/api",
  baseURL: "http://backend.zione.tech:5003/api",
  // baseURL: "http://localhost:5003/api",
});

http.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.statusCodeValue >= 400 &&
    error.response.statusCodeValue < 500;

  if (!expectedError) {
    console.error(error);
    alert("An unexpected error occurred");
  }
  return Promise.reject(error);
});

export default http;
