import http from "./http";

export function login(form) {
  return http.post("/user/login", form);
}

export function register(form) {
  return http.post("/user/register", form);
}

export function logout() {
  localStorage.removeItem("zamjy");
}

export function getCurrentUser() {
  return localStorage.getItem("zamjy")
    ? JSON.parse(localStorage.getItem("zamjy"))
    : null;
}

export function forgotPassword(email) {
  return http.get(`/user/otp/${email}`);
}

export function changePass(id, otp, data) {
  return http.patch(`/user/security/${id}/${otp}`, data);
}
