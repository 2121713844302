import React from "react";
import ServicesForm from "./ServicesForm";
import { addServices } from "../../services/services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ServicesAdd = () => {
  const navigate = useNavigate();

  const onAddServices = async (data) => {
    try {
      await addServices(data).then((res) => {
        if (res && res.data.status === 1) {
          toast.success(res.data.message);
          setTimeout(()=>{
            navigate("/menu");
          }, 10000)
        } else {
          toast.error(res.data.message);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  return <ServicesForm onSubmit={onAddServices} />;
};

export default ServicesAdd;
