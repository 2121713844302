import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import ImageCell from "../../components/ImageCell";
import { deleteServices } from "../../services/services";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
const ServiceTable = ({ data, setData }) => {
  const navigate = useNavigate();

  const handleDelete = (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you really want to delete this item? This process cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FF0000",
        confirmButtonText: "Delete",
      }).then((res) => {
        if (res.isConfirmed) {
          deleteServices(id).then((res) => {
            if (res && res.data.status === 1) {
              const filteredData = data.filter((x) => x.service_id !== id);
              setData(filteredData);
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
          });
        }
      });
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "This record may have already been deleted.",
        });
      }
    }
  };

  const columns = [
    {
      field: "imageUrl",
      headerName: "Image",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Image"
          style={{ width: 100, height: 150, objectFit: "cover" }}
        />
      ),
    },
    {
      field: "type",
      headerName: "Category",
      width: 200,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "service",
      headerName: "Product name",
      width: 350,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "active",
      headerName: "Actions",
      sortable: false,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate(`/menu/edit/${params.row.service_id}`);
              }}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleDelete(params.row.service_id)}
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: 550, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          ...data.initialState,
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pageSizeOptions={[25, 50, 100]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        getRowId={(row) => row.service_id}
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default ServiceTable;
