import React, { useState, useEffect } from "react";
import { editServices, getServicesbyID } from "../../services/services";
import { useNavigate, useParams } from "react-router-dom";
import ServiceForm from "./ServicesForm";
import { toast } from "react-toastify";
import SuspenseFallback from "../../components/SuspenseFallback";

const ServiceEdit = () => {
  const [initialValue, setinitialValue] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getService(+params.id);
  }, []);
  // console.log(initialValue);
  const getService = (id) => {
    getServicesbyID(id).then((res) => {
      if (res && res.data) {
        setinitialValue(res.data.object);
      }
    });
  };

  const onEdit = (form) => {
    try {
      editServices(+params.id, form).then((res) => {
        if (res && res.data.status === 1) {
          toast.success(res.data.message);
          setTimeout(()=>{
            navigate("/menu");
          }, 10000)

        } else {
          toast.error(res.data.message);
        }
      });
    } catch (error) {
      if (error.response && error.response.status) {
        alert(error.response.data.message);
      } else if (error.response && error.response.status === 404) {
        alert("This record may have already been deleted");
      }
    }
  };

  if (initialValue) {
    return (
      <ServiceForm
        InitialState={{
          type: initialValue.type,
          price: initialValue.price,
          service: initialValue.service,
          description: initialValue.description,
        }}
        onSubmit={onEdit}
      />
    );
  }
  return <SuspenseFallback />;
};

export default ServiceEdit;
