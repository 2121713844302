import React, { useEffect, useState } from "react";
import {
  Card,
  Box,
  Typography,
  Button,
  TextField,
  Container,
  Grid,
  CircularProgress,
  CardContent,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl
} from "@mui/material";
import { storage } from "../../services/firebase/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { getAllCategoriesByType } from "../../services/category";
import { getAllServices } from "../../services/services"; // Import the getAllServices function

const ServiceForm = ({ InitialState, onSubmit }) => {
  const [state, setState] = useState(
      InitialState || {
        type: "",
        service: "",
        price: 0,
        description: "",
        imageUrl: "",
      }
  );
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [services, setServices] = useState([]); // State to hold all services
  const [selectedServices, setSelectedServices] = useState([]); // State to hold selected services
  const navigate = useNavigate();
  const [category, setCategories] = useState([]);

  useEffect(() => {
    fetchCategoriesByType();
    fetchAllServices(); // Fetch all services when component mounts
  }, []);

  const fetchCategoriesByType = () => {
    getAllCategoriesByType("MENU").then(res => {
      if (res.data) {
        setCategories(res.data.object);
      }
    });
  };

  const fetchAllServices = () => {
    getAllServices().then(res => {
      if (res.data) {
        setServices(res.data.object);
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "service" && state.type === "SETS" && Array.isArray(value)) {
      const selected = value.map(item => {
        const [service, type] = item.split(" - ");
        return { service, type };
      });
      setSelectedServices(selected);
      setState({ ...state, [name]: selected.map(s => `${s.service} - ${s.type}`).join(", ") });
    } else {
      setState({ ...state, [name]: value });
    }
  };
console.log(state)
  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
  };

  const handleUpload = () => {
    if (!file) return;
    const storageRef = ref(storage, `zamjyProducts/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const updatedForm = {
              ...state,
              imageUrl: downloadURL,
            };
            onSubmit(updatedForm);
          });
        }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (file) {
      handleUpload();
    } else {
      onSubmit(state);
    }
  };

  return (
      <Container maxWidth="100%">
        <Typography fontWeight="bold" variant="h5" mb={2}>
          {InitialState === undefined || InitialState === null ? "Add" : "Edit"}{" "}
          Menu
        </Typography>
        <Card sx={{ padding: "20px" }}>
          <CardContent component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                      label="Category"
                      name="type"
                      value={state.type}
                      onChange={handleChange}
                  >
                    {category.map((category) => (
                        <MenuItem key={category.id} value={category.name}>
                          {category.name}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {state.type === "SETS" ? (
                    <FormControl fullWidth>
                      <InputLabel>Services</InputLabel>
                      <Select
                          label="Products"
                          name="service"
                          multiple
                          value={selectedServices.map(s => `${s.service} - ${s.type}`)}
                          onChange={handleChange}
                          renderValue={(selected) => selected.join(", ")}
                      >
                        {services.map((service) => (
                            <MenuItem key={service.id} value={`${service.service} - ${service.type}`}>
                              <Checkbox checked={selectedServices.findIndex(s => s.service === service.service && s.type === service.type) > -1} />
                              <ListItemText primary={service.service} />
                              {" - "}
                              <ListItemText primary={service.type} />
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                ) : (
                    <TextField
                        label="Product"
                        variant="outlined"
                        name="service"
                        value={state.service}
                        onChange={handleChange}
                        fullWidth
                    />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                    label="Price"
                    variant="outlined"
                    name="price"
                    value={state.price}
                    onChange={handleChange}
                    type="number"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                    label="Description"
                    variant="outlined"
                    name="description"
                    value={state.description}
                    onChange={handleChange}
                    type="text"
                    fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <label>Image</label>
                <TextField
                    variant="outlined"
                    name="file"
                    onChange={handleFileUpload}
                    type="file"
                    fullWidth
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" type="submit" disabled={isSubmitting}>
                {isSubmitting ? (
                    <div style={{ width: "50px" }}>
                      <CircularProgress size={20} />
                    </div>
                ) : (
                    <>{InitialState ? "Edit" : "Add"}</>
                )}
              </Button>

              <Button
                  variant="contained"
                  sx={{
                    ml: 1,
                  }}
                  onClick={() => {
                    navigate("/stock");
                  }}
                  color="error"
              >
                Cancel
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
  );
};

export default ServiceForm;