import {
  Avatar,
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Link,
  Container,
} from "@mui/material";
import React, { useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import { CheckBox } from "@mui/icons-material";
import { Copyright } from "../../components/Copyright";
import { useNavigate } from "react-router-dom";
import Joi from "joi";
import { login } from "../../services/auth";
import { toast } from "react-toastify";
import logo from "../../assets/zamjy.png";

const Login = () => {
  const navigate = useNavigate();
  // const [rememberMe, setRememberMe] = useState(false);
  const [form, setForm] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const schema = Joi.object({
    username: Joi.string().required(),
    password: Joi.string().required(),
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.currentTarget.name]: e.currentTarget.value,
    });

    const { error } = schema
      .extract(e.currentTarget.name)
      .label(e.currentTarget.name.toUpperCase())
      .validate(e.currentTarget.value);

    if (error) {
      setErrors({
        ...errors,
        [e.currentTarget.name]: error.details[0].message,
      });
    } else {
      delete errors[e.currentTarget.name];
      setErrors(errors);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await login(form);
      console.log(form);
      if (res.data && res.data.status === 1) {
        console.log(res.data.object.role);
        if (res.data.object.role === "admin" || res.data.object.role === "cashier") {
          localStorage.setItem("zamjy", JSON.stringify(res.data.object));
          toast.success(res.data.message);
          navigate("/dashboard");
        } else {
          toast.error("You are not an admin");
        }
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            objectFit: "contain",
            width: 150,
            height: 150,
            marginBottom: 5,
          }}
        />

        <Typography component="h1" variant="h5" fontWeight="bold">
          Sign in to Zamjy
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            name="username"
            label="Username"
            value={form.username}
            onChange={handleChange}
            error={!!errors.username}
            helperText={errors.username}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            value={form.password}
            onChange={handleChange}
            error={!!errors.password}
            helperText={errors.password}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            {/* <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid> */}
            {/* <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid> */}
          </Grid>
          <Link color="inherit" href="/forgotpassword">
            Forgot Password
          </Link>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </Container>
  );
};

export default Login;
