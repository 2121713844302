import React, { useEffect, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { getCurrentUser, logout } from "../services/auth";
import { useNavigate } from "react-router-dom";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { mockNotifications } from "../constants/mock";
import {
  getAllNotification,
  getStatusNotification,
} from "../services/notification";
import { humanizeTime } from "../constants";

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const IconPopover = ({ anchorEl, handleClose, handleClick }) => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getAllNotifications();
  }, []);

  const getAllNotifications = () => {
    getAllNotification().then((res) => {
      if (res && res.data) {
        const sortedData = res.data.object.sort(
          (a, b) => new Date(b.date_modified) - new Date(a.date_modified)
        );
        setCount(res.data.status);
        setData(sortedData);
      }
    });
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleClickStatus = () => {
    getStatusNotification();
    setCount(0);
  };

  const handleItemClick = (item) => {
    if (item.type === "order") {
      navigate("/orders");
    } else if (item.type === "reservation") {
      navigate("/reservations");
    }
  };

  const user = getCurrentUser();
  // console.log(count);
  return (
    <>
      <Typography fontWeight="bold" mr={1}>
        Hi! {user.firstName} {user.lastName}
      </Typography>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={count} color="info">
          <NotificationsActiveIcon onClick={handleClickStatus} />
        </Badge>
      </IconButton>
      <IconButton color="inherit" onClick={handleLogout}>
        <LogoutIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            maxHeight: 320,
            width: 300,
            overflow: "auto",
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {data.map((item, index) => (
              <ListItemButton onClick={() => handleItemClick(item)} key={index}>
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {item.message}
                      </Typography>

                      <br />
                      <span style={{ fontSize: 12 }}>
                        {humanizeTime(item.date_modified)}
                      </span>
                    </React.Fragment>
                  }
                />
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default IconPopover;
