import http from "./http";

export function getAllCategories() {
  return http.get("/category/all");
}

export function getAllCategoriesByType(type) {
  return http.get(`/category/all/by-type/${type}`);
}
export function addCategory(data) {
  return http.post(`/category/add`, data);
}

export function deleteCategory(id) {
  return http.delete(`/category/delete/${id}`);
}
